<template>
  <div class="zhaopin">
    <div class="img">
      <img src="../../common/image/组 24.png" alt="" />
    </div>
    <!-- 招聘信息 -->
    <div class="main">
      <div class="top">
        <img src="../../common/image/招聘信息.png" alt="" />
      </div>
      <!-- 招聘职位列表 -->
      <div class="bottom flexsb">
        <div
          class="box flexsb"
          style="cursor: pointer"
          v-for="(item, k) in list"
          :key="k"
          @click="
            $router.push({ name: 'recruitdetail', query: { id: item.id } })
          "
        >
          <div class="left">
            <div class="font">
              <b>{{ item.postName }}</b>   <b style="color: #981c1c">{{ item.salary }}k</b>
            </div>
            <div>
              {{ item.workAddres }} | {{ item.workExperience }} |
              {{ item.education }}
            </div>
          </div>
          <div class="right">
            <div style="color: #981c1c">查看详情</div>
            <div style="color: #999999">发布时间：{{ item.createTime }}</div>
            <div>{{ item.company }}</div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagination" v-if="total>10">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pagesize"
          layout="prev, pager, next,total, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "zhaopin",
  props: ["datalist"],
  data() {
    return {
      currentPage: 1, // 当前页
      list: [], // 职位列表
      total: 0, // 总条数
      pagesize: 10, // 每页条数
    };
  },
  created() {
    this.getzhaopin(1, 10); // 招聘信息
  },
  wacth: {},
  methods: {
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.getzhaopin
      this.getzhaopin(this.currentPage, this.pagesize);
    },
    // 招聘信息
    getzhaopin(currentPage, pagesize) {
      this.api .recruit({ pageNum: currentPage,  pageSize: pagesize,  }).then((res) => {
          this.list = res.data.rows;
          this.total = res.data.total;
        })
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.zhaopin {
  .img {
    img {
      width: 100%;
    }
  }
  .main {
    width: 1200px;
    margin: 0 auto;
    margin-top: 56px;
    img {
      margin-bottom: 56px;
      width: 100%;
      height: 100%;
    }
    .bottom {
      flex-wrap: wrap;
      .box {
        font-size: 14px;
        width: 590px;
        height: 140px;
        padding: 15px 16px 15px 30px;
        box-sizing: border-box;
        background: #ffffff;
        border: 1px solid #eeeeee;
        box-shadow: 0px 10px 8px 0px rgba(250, 250, 250, 0.3);
        border-radius: 2px;
        // align-items: center;
        margin-bottom: 20px;
        .left {
          display: flex;
          //   justify-content: space-around;
          justify-content: center;
          flex-direction: column;
          .font {
            margin-bottom: 17px;
          }
        }
        .right {
          text-align: right;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          div {
            // margin-bottom: 16px;
          }
        }
      }
    }
    .pagination {
      text-align: center;
      margin: 20px 0;
    }
  }
}
</style>
